import React, { useState } from 'react';
import { FaGithub, FaLinkedin, FaInstagram, FaBars, FaReact, FaDocker, FaEnvelope, FaWhatsapp } from 'react-icons/fa'; 
import { SiTailwindcss, SiNodedotjs, SiOracle, SiAzuredevops } from 'react-icons/si';
import './Portifolio.css';
import Vinicius from '../../assets/ViniciusMelo.png';

const Portifolio = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="min-h-screen bg-cyan-800">
      <header className="flex justify-between items-center p-4 bg-white shadow">
        <div className="flex space-x-4">
          <a href="https://github.com/vininiciusviana" target="_blank" rel="noopener noreferrer" className="text-black hover:text-gray-900">
            <FaGithub size={24} />
          </a>
          <a href="https://www.linkedin.com/in/vinicius-viana-de-melo/" target="_blank" rel="noopener noreferrer" className="text-blue-700 hover:text-blue-900">
            <FaLinkedin size={24} />
          </a>
          <a href="https://www.instagram.com/techvimelo?igsh=dmFnbzdyaGNmdHVj" target="_blank" rel="noopener noreferrer" className="text-pink-600 hover:text-pink-800">
            <FaInstagram size={24} />
          </a>
        </div>
        <div className="md:hidden flex items-center space-x-4">
          <button onClick={toggleMenu} className="text-gray-700 hover:text-gray-900 focus:outline-none">
            <FaBars size={24} />
          </button>
          {isOpen && (
            <nav className="absolute right-4 top-16 flex flex-col space-y-2 bg-white shadow p-4 rounded-lg">
              <a href="#carreira" className="text-gray-700 hover:bg-blue-100 hover:text-gray-900 rounded-2xl p-1" onClick={toggleMenu}>Carreira</a>
              <a href="#projetos" className="text-gray-700 hover:bg-blue-100 hover:text-gray-900 rounded-2xl p-1" onClick={toggleMenu}>Projetos</a>
              <a href="#skills" className="text-gray-700 hover:bg-blue-100 hover:text-gray-900 rounded-2xl p-1" onClick={toggleMenu}>Skills</a>
              <a href="#contato" className="text-gray-700 hover:bg-blue-100 hover:text-gray-900 rounded-2xl p-1" onClick={toggleMenu}>Contato</a>
            </nav>
          )}
        </div>
        <nav className="hidden md:flex items-center space-x-6">
          <a href="#carreira" className="text-gray-700 hover:bg-blue-200 hover:text-gray-900 rounded-2xl px-2 py-1">Carreira</a>
          <span className="text-gray-400">|</span>
          <a href="#projetos" className="text-gray-700 hover:bg-blue-200 hover:text-gray-900 rounded-2xl px-2 py-1">Projetos</a>
          <span className="text-gray-400">|</span>
          <a href="#skills" className="text-gray-700 hover:bg-blue-200 hover:text-gray-900 rounded-2xl px-2 py-1">Skills</a>
          <span className="text-gray-400">|</span>
          <a href="#contato" className="text-gray-700 hover:bg-blue-200 hover:text-gray-900 rounded-2xl px-2 py-1">Contato</a>
        </nav>
      </header>

      <section className="flex flex-col items-center justify-center p-8 mt-8">
        <div className="flex flex-col md:flex-row items-center md:justify-center">
          <div className="flex flex-col items-center mb-8 md:mb-0 md:mr-8">
            <img
              src={Vinicius}
              alt="Vinicius Melo"
              className="w-80 h-80 rounded-full mb-4"
            />
            <h1 className="text-5xl text-white font-bold text-center">Vinicius Melo</h1>
            <p className="text-4xl text-gray-300 text-center">Desenvolvedor Fullstack</p>
          </div>
          <div id="carreira" className="text-center text-white md:text-left md:w-1/2">
            <h2 className="text-2xl font-bold mb-4">Carreira</h2>
            <div className="mb-8">
              <h3 className="text-xl font-semibold">Maio 2023 - Atualmente</h3>
              <h4 className="text-lg font-medium">Desenvolvedor Fullstack</h4>
              <p className="text-gray-300">Braspress Transportes Urgentes - Presencial, SP-Brasil</p>
              <div className="flex flex-wrap justify-center md:justify-start space-x-2 mt-2">
                <button className="btn-tech flex items-center mb-2 md:mr-2" style={{ color: '#61DAFB' }}>
                  <FaReact size={18} className="mr-2 icon-rotate" />
                  React
                </button>
                <button className="btn-tech flex items-center mb-2 md:mr-2" style={{ color: '#38B2AC' }}>
                  <SiTailwindcss size={18} className="mr-2" />
                  Tailwind
                </button>
                <button className="btn-tech flex items-center mb-2 md:mr-2" style={{ color: '#339933' }}>
                  <SiNodedotjs size={18} className="mr-2" />
                  Node.Js
                </button>
                <button className="btn-tech flex items-center mb-2 md:mr-2" style={{ color: '#F80000' }}>
                  <SiOracle size={18} className="mr-2" />
                  Oracle PL/SQL
                </button>
                <button className="btn-tech flex items-center mb-2 md:mr-2" style={{ color: '#1488C6' }}>
                  <FaDocker size={18} className="mr-2" />
                  Docker
                </button>
                <button className="btn-tech flex items-center mb-2 md:mr-2" style={{ color: '#0078D7' }}>
                  <SiAzuredevops size={18} className="mr-2" />
                  Azure Devops
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="p-8">
        <div id="projetos" className="text-center mb-16">
          <h2 className="text-2xl text-white font-bold mb-4">Projetos</h2>
          <p className='text-lg text-gray-300'>Em construção, volte mais tarde.</p>
        </div>
        <div id="skills" className="text-center mb-16">
          <h2 className="text-2xl text-white font-bold mb-4">Skills</h2>
          <p className='text-lg text-gray-300 '>Desenvolvedor fullstack com experiência no setor logístico, especializado em desenvolvimento de software e regras de negócio. 
            Utilizo banco de dados Oracle, Node.js, Docker, React, Tailwind, PL/SQL e Azure DevOps para criar soluções robustas e eficientes. 
            Tenho habilidades em desenvolvimento de APIs com Node.js, containerização de aplicações com Docker, criação de interfaces de usuário 
            dinâmicas e responsivas com React e Tailwind, além de manipulação de dados e automação de processos com PL/SQL. 
            Também realizo integrações de dados via NiFi e gerencio pipelines de desenvolvimento e deploy com Azure DevOps.</p>
        </div>

        <div id="contato" className="text-center mb-16">
          <h2 className="text-2xl text-white font-bold mb-4">Contato</h2>
          <p className="text-lg text-gray-300 mb-4">
            <FaEnvelope size={20} className="inline-block mr-2 text-blue-400" />
            <a href="mailto:vinicius@techvimelo.com" className="text-blue-400 hover:text-blue-600">vinicius@techvimelo.com</a>
          </p>
          <p className="text-lg text-gray-300">
            <FaWhatsapp size={20} className="inline-block mr-2 text-green-400" />
            <a href="https://api.whatsapp.com/send?phone=+5511993748274&text=Ol%C3%A1,%20eu%20vim%20atrav%C3%A9s%20do%20seu%20site%20e%20gostaria%20de%20falar%20com%20você" className="text-green-400 hover:text-green-600">Whatsapp</a>
          </p>
        </div>
      </section>
        <footer className="p-4 bg-white shadow text-center text-gray-700">
        <p>&copy; 2023 Techvimelo. Todos os direitos reservados.</p>
        </footer>
    </div>
  );
};

export default Portifolio;
